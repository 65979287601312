$(document).ready(function(){
	$(window).load(function(){
    homepageBanner();
    equalizer();
    slickSliders();
    attachButton();

		// Global Variables
		var $pageHeight = $(window).height(),
		$pageWidth = $(window).width(),
		$navHeight = $('header.site-header').outerHeight(),
		$quickLinks = $('nav.site-quicklinks').outerHeight(),
		$footerHeight = $('footer.site-footer').outerHeight();

    $(window).on('resize', function(){
      homepageBanner();
      equalizer();
      slickSliders();
    });

	});
});

function homepageBanner(){
  var $fullHeight = $(window).height();
  var $menuHeight = $('header.site-header').outerHeight();
  $('.homepage-banner-wrapper .banner-item').css({
    'height' : $fullHeight - $menuHeight
  });
  $('.homepage-banner-wrapper .banner-item .content-wrapper').css({
    'height' : $fullHeight - $menuHeight
  });
}

function equalizer(){
  var maxHeight = 0;
  $(".equalize").each(function(){
     if ($(this).height() > maxHeight) { maxHeight = $(this).height(); }
  });
  $(".equalize").height(maxHeight);

  if ($('.list_of_value-wrap').is(':visible')) {
    var $higherHeight = 0;
    $('.value-wrap .value-item').each(function(){
      if ($(this).height() > $higherHeight) { $higherHeight = $(this).height(); }
    });
    $(".value-wrap .value-item").height($higherHeight);
  }

  if ($('.object_list-wrapper').is(':visible')) {
    var $higherHeight = 0;
    $('.object-wrapper').each(function(){
      if ($(this).height() > $higherHeight) { $higherHeight = $(this).height(); }
    });
    $(".object_list-wrapper .object-wrapper").height($higherHeight);
  }
  if ($('.services_list-wrapper').is(':visible')) {
    $('.services_list-wrapper .col-md-6').each(function(){
      var $contentHeight = $(this).find('.content').outerHeight();
      console.log($contentHeight);
      $(this).find('.service-img-wrap').css({
        'height' : $contentHeight
      });
    });
  }

  if ($('.services_list-wrapper').is(':visible')) {
    var $higherHeights = 0;
    $('.services_list-wrapper .service-item').each(function(){
      if ($(this).height() > $higherHeights) { $higherHeights = $(this).height(); }
    });
    $(".services_list-wrapper .service-item").height($higherHeights);
    setTimeout(function(){
      var $heiiii = $(".services_list-wrapper .service-item").outerHeight();
      $('.services_list-wrapper .service-item .inquire-btn-wrap .inquire-btn').height($heiiii);
    }, 500);
  }
}

function slickSliders(){
  $('.partners-list-wrapper').slick({
    dots: false,
    infinite: false,
    speed: 300,
    slidesToShow: 6,
    slidesToScroll: 1,
    arrows: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 1,
          infinite: false,
          dots: false
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  });
   $('.projects-gallery-wrapper').slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    fade: false,
    asNavFor: '.projects-gallery-navigation'
  });
  $('.projects-gallery-navigation').slick({
    slidesToShow: 6,
    slidesToScroll: 1,
    asNavFor: '.projects-gallery-wrapper',
    dots: false,
    arrows: false,
    centerMode: false,
    focusOnSelect: true
  });

  $('.post-gallery-wrap').slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    infinite: false,
    speed: 600,
    fade: true,
    cssEase: 'linear'
  });
  $('.post-gallery-navigation').slick({
    slidesToShow: 4,
    arrows: true,
    nextArrow: '<div class="slick-arrow-div-right"><i class="fa fa-chevron-right"></i></div>',
    prevArrow: '<div class="slick-arrow-div-left"><i class="fa fa-chevron-left"></i></div>',
    rows: 2,
    slidesToScroll: 1,
    infinite: false,
    dots: true,
    centerMode: false,
    focusOnSelect: true
  });

  if ($('.slick-arrow-div-left.slick-arrow').hasClass('slick-disabled')) {
    setTimeout(function(){
      $('.arw-prev-brand').css('display', 'none');
    }, 200);
  }

  $(document).on('click', '.arw-prev-brand', function(){
    $('.slick-arrow-div-left.slick-arrow').click();
  });
  $(document).on('click', '.arw-next-brand', function(){
    $('.slick-arrow-div-right.slick-arrow').click();
  });
  $( ".post-gallery-navigation ul.slick-dots" ).before( $( '<div class="slick-post-arrow arw-prev-brand"><i class="fas fa-chevron-left"></i></div>' ) );
  $( ".post-gallery-navigation ul.slick-dots" ).after( $( '<div class="slick-post-arrow arw-next-brand"><i class="fas fa-chevron-right"></i></div>' ) );

  var stopslide = false;
  var navSlide = $('.post-gallery-navigation');
  var navItem = navSlide.find('.post-navigation-item');
  navItem.on('click', function(e){
    if (!stopslide) {
      e.stopPropagation();
      var idx = $(this).data("thumbkey");
      $('.post-gallery-wrap').slick("slickGoTo", idx);
    }
  });

  $('.post-gallery-navigation').on("beforeChange", function() {
      stopslide = true;
      if ($('.slick-arrow-div-left.slick-arrow').hasClass('slick-disabled')) {
        setTimeout(function(){
          $('.arw-prev-brand').css('display', 'none');
        }, 200);
      }else{
        setTimeout(function(){
          $('.arw-prev-brand').css('display', 'inline-block');
        }, 200);
      }
      if ($('.slick-arrow-div-right.slick-arrow').hasClass('slick-disabled')) {
        setTimeout(function(){
          $('.arw-next-brand').css('display', 'none');
        }, 200);
      }else{
        setTimeout(function(){
          $('.arw-next-brand').css('display', 'inline-block');
        }, 200);
      }
  }).on("afterChange", function() {
      stopslide = false;
      if ($('.slick-arrow-div-left.slick-arrow').hasClass('slick-disabled')) {
        setTimeout(function(){
          $('.arw-prev-brand').css('display', 'none');
        }, 200);
      }else{
        setTimeout(function(){
          $('.arw-prev-brand').css('display', 'inline-block');
        }, 200);
      }
      if ($('.slick-arrow-div-right.slick-arrow').hasClass('slick-disabled')) {
        setTimeout(function(){
          $('.arw-next-brand').css('display', 'none');
        }, 200);
      }else{
        setTimeout(function(){
          $('.arw-next-brand').css('display', 'inline-block');
        }, 200);
      }
  });
}

function attachButton(){
  $(document).on('click', '.attach-resume', function(){
    $('.fileAttachResume #attachresume').click();
    $('.fileAttachResume #attachresume').change(function(e){
    var fileName = e.target.files[0].name;
    $('.attach-resume .attach-label').text(fileName);
  });
  });
}